import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/storage'
import './assets/css/reset.less'
import { Table, InputNumber, Icon, Checkbox, Tabs } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

Vue.component('ImageUpload', ImageUpload)

Vue.use(ElementUI);
Vue.use(Table)
Vue.use(InputNumber)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(Tabs)

Vue.config.productionTip = false
Vue.prototype.$storage = storage

const vm = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

export default vm;