const STORAGE_KEY = 'storeStorage'; // 所有本项目的缓存都存在这里
export default {
  // key: mall 
  // 存储值  module_name为模块对象，即user
  setItem(key, value, module_name) {
    console.log(key, value)
    if(module_name){
      // 获取user对象
      let val=this.getItem(module_name);
      val[key] = value;
      this.setItem(module_name,val);
    }else{
      let val = this.getStorage();
      val[key] = value;
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(val));
    }
  },
  // 取某一个模块下的属性 (user下面的userName)
  getItem(key, module_name) {
    if (module_name) {
      // 返回一个Object对象
      let val = this.getItem(module_name);
      if (val) return val[key];
    }
    return this.getStorage()[key];
  },
  // 获取整个数据
  getStorage() {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '{}')
  },
  // 清空某一个值
  clearItem(key,module_name) {
    let val=this.getStorage();
    if(module_name){
      if(!val[module_name]) return;
        delete val[module_name][key];
    }else{
        delete val[key];
    }
    // 删除后将值重新写入
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(val));
  },
  // sessionStorage存储
  seSetItem(key, value, module_name) {
    if(module_name){
      // 获取user对象
      let val = this.getItem(module_name);
      val[key] = value;
      this.seSetItem(module_name,val);
    }else{
      let val = this.seGetStorage();
      val[key] = value;
      window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(val));
    }
  },
  // 获取整个数据
  seGetStorage() {
    return JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) || '{}')
  },
  seGetItme(key, module_name) {
    if (module_name) {
      // 返回一个Object对象
      let val = this.seGetItme(module_name);
      if (val) return val[key];
    }
    return this.seGetStorage()[key];
  },
  // 清空某一个值
  seClearItem(key,module_name) {
    let val=this.seGetStorage();
    if(module_name){
      if(!val[module_name]) return;
        delete val[module_name][key];
    }else{
        delete val[key];
    }
    // 删除后将值重新写入
    window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(val));
  },
}
