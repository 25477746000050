var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{ref:"tab",staticClass:"tab"},[_c('img',{staticStyle:{"width":"45px","height":"45px","border-radius":"20%"},attrs:{"src":require("../assets/images/logo.png")}}),_c('div',{staticClass:"flex1"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticStyle:{"margin":"0 58px 0 0","font-weight":"600","cursor":"pointer"}},[_c('div',{class:{ active: _vm.active === index },on:{"click":function($event){return _vm.switchTab(index,item)}}},[_vm._v(" "+_vm._s(item)+" ")])])}),0)]),_c('div',{ref:"cont",staticClass:"cont"},[_c('div',{ref:"cont_1",staticStyle:{"text-align":"center"}},[_vm._m(0),_c('img',{staticStyle:{"width":"960px","height":"486px","margin-top":"132px"},attrs:{"src":require("../assets/images/shouye1.png")}})]),_c('div',{ref:"cont_2"},[_c('div',{staticStyle:{"text-align":"center","font-weight":"600","margin":"97px 0 52px 0","font-size":"48px","color":"rgba(0,0,0,0.88)"}},[_vm._v("产品服务")]),_vm._m(1),_vm._m(2)]),_c('div',{ref:"cont_3"},[_c('div',{staticStyle:{"height":"72px"}}),_c('div',{staticStyle:{"text-align":"center","font-weight":"600","margin":"97px 0 52px 0","font-size":"48px","color":"rgba(0,0,0,0.88)"}},[_vm._v("专家服务")]),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{ref:"cont_4",staticClass:"cont_4"},[_c('div',{staticStyle:{"height":"128px"}}),_c('div',{staticStyle:{"font-size":"48px","color":"#000","text-align":"center","font-weight":"600"}},[_vm._v("关于我们")]),_c('div',{staticStyle:{"height":"57px"}}),_vm._m(6),_c('div',{staticStyle:{"background":"#89AFD0","height":"150px","font-size":"16px","text-align":"center","color":"#fff","line-height":"150px"}},[_vm._v(" 备案号:京ICP备2023021456号-11 ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex shouye"},[_c('div',{staticStyle:{"margin-right":"73px"}},[_c('div',{staticClass:"text1"},[_vm._v("AI规划+专家咨询+平台辅导")]),_c('div',{staticClass:"text2",staticStyle:{"margin-top":"16px"}},[_vm._v("助力青年人")]),_c('div',{staticClass:"text2"},[_vm._v("求职及职业发展")])]),_c('img',{staticStyle:{"width":"432px","height":"327px"},attrs:{"src":require("../assets/images/shouye.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"margin-right":"37px","color":"rgba(0,0,0,0.88)"}},[_c('div',{staticStyle:{"font-size":"22px"}},[_vm._v(" 央国企、互联网、高科技、"),_c('br'),_vm._v(" 绿色环保、大健康 ")]),_c('div',{staticClass:"flex1",staticStyle:{"margin":"10px 0 2px 0"}},[_c('div',{staticStyle:{"color":"#FF662C","font-size":"42px"}},[_vm._v("1000")]),_c('div',{staticStyle:{"color":"#FF662C","margin-bottom":"20px","font-size":"44px"}},[_vm._v("+")]),_c('div',{staticStyle:{"font-size":"36px","color":"#333"}},[_vm._v("各行业职场专家")])]),_c('div',{staticStyle:{"background":"#F5F5F5","border-radius":"24px","padding":"24px","font-size":"19px"}},[_c('div',[_vm._v("资深职场专家1V1指导")]),_c('div',{staticStyle:{"margin":"16px 0"}},[_vm._v("平均面试成功率提高80%")]),_c('div',[_vm._v("职业推荐、自我介绍、薪资谈判")])]),_c('img',{staticStyle:{"width":"282px","height":"97px","margin-top":"82px"},attrs:{"src":require("../assets/images/weixin.png")}})]),_c('img',{staticStyle:{"width":"550px","height":"576px"},attrs:{"src":require("../assets/images/chanpin.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex2",staticStyle:{"margin-top":"58px"}},[_c('img',{staticStyle:{"width":"550px","height":"576px"},attrs:{"src":require("../assets/images/ai.png")}}),_c('div',{staticStyle:{"text-align":"right","margin-left":"-110px"}},[_c('div',{staticStyle:{"font-size":"48px","color":"rgba(0,0,0,0.88)","margin-bottom":"32px"}},[_vm._v("AI简历")]),_c('div',{staticStyle:{"font-size":"28px","color":"rgba(0,0,0,0.88)"}},[_vm._v(" 根据岗位需求快速生成专属简历更你的简历更 ")]),_c('img',{staticStyle:{"width":"282px","height":"97px","margin-top":"93px"},attrs:{"src":require("../assets/images/weixin.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"box-shadow":"1px 13px 32px 1px rgba(0,0,0,0.08)","border-radius":"46px","height":"489px","width":"754px","padding":"64px 0 0 60px"}},[_c('div',{staticStyle:{"font-weight":"600","font-size":"38px","color":"rgba(0,0,0,0.88)"}},[_vm._v("职涯罗盘")]),_c('div',{staticStyle:{"font-size":"19px","color":"rgba(0,0,0,0.88)","line-height":"36px","width":"384px","margin":"30px 0 34px 0"}},[_vm._v(" \"职涯罗盘\"是一款专为应届大学生量身定制的职涯规划服务。"),_c('br'),_vm._v(" 帮助每位毕业生都渴望在职场上找到自己的定位和发展方向。 ")]),_c('img',{staticStyle:{"width":"282px","height":"97px"},attrs:{"src":require("../assets/images/weixin.png")}})]),_c('img',{staticStyle:{"width":"436px","height":"559px","margin-left":"-209px"},attrs:{"src":require("../assets/images/luopan.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('img',{staticStyle:{"width":"436px","height":"600px","margin-right":"-209px","z-index":"999","margin-top":"68px"},attrs:{"src":require("../assets/images/duoshou.png")}}),_c('div',{staticStyle:{"box-shadow":"1px 13px 32px 1px rgba(0,0,0,0.08)","border-radius":"46px","width":"754px","padding":"64px 0 0 60px","padding-left":"259px"}},[_c('div',{staticStyle:{"font-weight":"600","font-size":"38px","color":"rgba(0,0,0,0.88)"}},[_vm._v("简历舵手")]),_c('div',{staticStyle:{"font-size":"19px","color":"rgba(0,0,0,0.88)","line-height":"36px","width":"384px","margin":"30px 0 34px 0"}},[_vm._v(" '简历舵手\"是一款专为应届大学生设计的简历优化服务。"),_c('br'),_vm._v(" 我们的专家团队将根据每位求职者的行业偏好、目标企业和理想岗位，量身定制简历，确保每一份简历都能精准地传达求职者的专业技能和个人特质。 ")]),_c('img',{staticStyle:{"width":"282px","height":"97px"},attrs:{"src":require("../assets/images/weixin.png")}}),_c('div',{staticStyle:{"height":"28px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticStyle:{"box-shadow":"1px 13px 32px 1px rgba(0,0,0,0.08)","border-radius":"46px","height":"489px","width":"754px","padding":"64px 0 0 60px"}},[_c('div',{staticStyle:{"font-weight":"600","font-size":"38px","color":"rgba(0,0,0,0.88)"}},[_vm._v("灯塔之光")]),_c('div',{staticStyle:{"font-size":"19px","color":"rgba(0,0,0,0.88)","line-height":"36px","width":"384px","margin":"30px 0 34px 0"}},[_vm._v(" \"灯塔之光\"是一款专为应届大学生设计的求职面试辅导服务。"),_c('br'),_vm._v(" \"灯塔之光\"服务通过邀请目标公司、对应岗位的在职专家，为求职者提供精准、实用的面试模拟和问题回答指导。 ")]),_c('img',{staticStyle:{"width":"282px","height":"97px"},attrs:{"src":require("../assets/images/weixin.png")}})]),_c('img',{staticStyle:{"width":"436px","height":"559px","margin-left":"-209px"},attrs:{"src":require("../assets/images/dengta.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',[_c('div',{staticClass:"textdi"},[_vm._v("邮箱：1504452775@qq.com")]),_c('div',{staticClass:"textdi",staticStyle:{"margin":"32px 0"}},[_vm._v("商务/联系方式：17316127135")]),_c('div',{staticClass:"textdi"},[_vm._v("客服联系方式：dengtazhiya001")])]),_c('img',{staticStyle:{"width":"336px","height":"256px"},attrs:{"src":require("../assets/images/beian.png")}})])
}]

export { render, staticRenderFns }